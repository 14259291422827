<template>
  <div>
    <Header :navbg='true'></Header>
    <div class="container">
      <div class="title">投诉建议</div>
      <div class="greetings">
        您好，感谢您对小院生活的关注与支持。
      </div>
      <div class="desc">
        如果您对我们的工作和服务有任何意见、建议，请填写以下内容进行反馈，您的反馈对我们非常重要。我们将万分感谢！
        为了使您的反馈得到及时回复和处理，请您务必完整填写以下信息。谢谢！
      </div>
      <div class="inputWarp type">
        <div class="lable">类型</div>
        <van-radio-group v-model="type" class="typeWarp">
          <van-radio name="1" class="item">投诉</van-radio>
          <van-radio name="2" class="item">建议</van-radio>
        </van-radio-group>
      </div>
      <div class='inputWarp'>
        <div class='lable'>姓名</div>
        <input class='name' placeholder="请输入您的姓名" type="text" v-model="name"/>
      </div>
      <div class='inputWarp'>
        <div class='lable'>联系方式</div>
        <input class='mobile' placeholder="请输入您的联系方式" type="number" v-model="mobile"/>
      </div>
      <div class='complainWarp'>
        <div class='lable'>请详细描述您的建议、意见、问题等：</div>
        <textarea placeholder="请详细描述您的建议、意见、问题" v-model="advice"></textarea>
      </div>
      <button class='btn' @click="submit">提交</button>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/head2022.vue'
import Footer from '@/components/foot2022.vue'
import utils from '@/common/utils'
import { mapState } from 'vuex'

export default {
  name:"feedback",
  data () {
    return {
      type: "1",
      name: "",
      mobile: "",
      advice: "",
    }
  },
  components: {
    Header,
    Footer,
  },
  computed: {
    ...mapState(['ocApi', 'user_info']),
  },
  created () {
    if (this.user_info && this.user_info.fullname) {
      this.name = this.user_info.fullname
    }
    if (this.user_info && this.user_info.telephone) {
      this.mobile = this.user_info.telephone
    }
  },
  methods: {
    submit: utils.debounce(function () {
      let that = this;
      if (!that.type) {
        that.$toast('请选择类型');
        return;
      }
      if (!that.name) {
        that.$toast('请输入姓名');
        return;
      }
      if (!utils.isMobile(that.mobile)) {
        that.$toast('请输入正确的手机号');
        return;
      }
      if (!that.advice) {
        that.$toast('请输入您的意见与建议');
        return;
      }
      that.$axios.post(that.ocApi + 'user/complain', {
        "complain_subject_content": that.type,
        "complain_name": that.name,
        "complain_phone": that.mobile,
        "complain_content": that.advice
      })
        .then(res => {
          if (res.data.code == 1) {
            that.$toast.success('您的意见已提交!');
            that.advice = ""
          } else {
            that.$toast(res.data.message);
          }
        })

    }, 200, true),
  },
}
</script>

<style lang="scss" scoped>
.container {
  font-size: 14px;
  color: #595757;
  padding: 0 15px;
  padding-top: 84px;
}

.container .title {
  width: 100%;
  color: #00cb87;
  font-size: 19px;
  text-align: center;
}

.container .greetings {
  margin-top: 28px;
  line-height: 24px;
}

.container .desc {
  line-height: 24px;
  text-indent: 30px;
  margin-bottom: 22.5px;
}

.container .inputWarp {
  margin-top: 15px;
  display: flex;
  align-items: center;
  height: 40px;
}

.container .inputWarp .lable {
  width: 61px;
  margin-right: 6.5px;
  display: flex;
}

.container .inputWarp input {
  flex: 1;
  background: #f7f7f7;
  border-radius: 2.5px;
  padding: 9px 13px;
}

.container .complainWarp .lable {
  margin: 14px 0;
}

.container .complainWarp textarea {
  background-color: #f7f7f7;
  border-radius: 2.5px;
  width: 100%;
  height: 124px;
  padding: 13px 10.5px;
  box-sizing: border-box;
  border: none;
}

.container .btn {
  width: 100%;
  height: 38px;
  margin-top: 30px;
  background-color: #00cb87;
  color: #f7f7f7;
  font-size: 17px;
  border-radius: 2.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.container .type .typeWarp {
  display: flex;
  .item{
    margin-right: 20px;
  }
}

</style>